<template>
  <div class="inspection-assign-container">
    <b-steps v-if="!hasCreateError"
             v-model="activeStep"
             :animated="true"
             :rounded="true"
             :has-navigation="false">
      <b-step-item step="1"
                   label="Ellenőrizendő személy kiválasztása"
                   :clickable="false">
        <h1 class="title has-text-centered">Ellenőrizendő személyek</h1>
        <InspectableMembersTable v-if="inspectableMembers.length > 0"
                                 :table-data="inspectableMembers"
                                 @deleteAssignment="deleteAssignment"
                                 @selectMember="selectMember"/>
        <h2 v-else-if="!isLoading"
            class="title is-3">Nem takálható ellenőrzés</h2>
        <BasicLoading class="mt-6"
                      :is-active="isLoading"/>
      </b-step-item>
      <b-step-item step="2"
                   label="Ügyfél választás"
                   :clickable="false">
        <h1 class="title has-text-centered">Ügyfél választás</h1>
        <div class="buttons">
          <b-button @click="backStep"
                    class=""
                    type="is-primary"
                    icon-left="arrow-circle-left">
            Vissza
          </b-button>
        </div>
        <!--        TODO: separate to component-->
        <div v-if="selectedMember"
             class="columns is-multiline has-background-grey-lighter mt-2 is-gapless p-2">
          <div class="column is-3 has-text-weight-bold">Név</div>
          <div class="column is-6">
            {{ selectedMember.name }}
            <b-tag :type="getInspectionSelectionStatusClass(selectedMember.selection_status)"
                   class="is-radiusless">
              {{ selectedMember.selection_status_name }}
            </b-tag>
          </div>
          <div class="column is-1 has-text-weight-bold">Kiv. oka</div>
          <div class="column is-2">
            {{ selectedMember.selection_reason }}
          </div>
          <div class="column is-3 has-text-weight-bold">MER ellenőrzésre kijelölve a következő jogviszonyban</div>
          <div class="column is-9">
            <div v-if="selectedMember.connected_inspections && selectedMember.connected_inspections.length > 0">
              <b-button v-for="connectedInspection in selectedMember.connected_inspections"
                        :key="`clientConnectedInspection_${connectedInspection.inspection_id}`"
                        type="is-ghost"
                        class="text-button-height-modifier"
                        @click="selectConnectedMember(connectedInspection.inspection_id)">
                {{ connectedInspection.name }}
              </b-button>
            </div>
            <div v-else>-</div>
          </div>
          <div class="column is-3 has-text-weight-bold">Átlagos jelentésszám</div>
          <div class="column is-3">
            {{ selectedMember.average_commission_count }} db, kiválasztható jelentés:
            {{ selectedMember.selectable_commission_count }} db,
          </div>
          <div class="column is-3 has-text-weight-bold">Kiválasztott jelentésszám:</div>
          <div class="column is-3">
            {{ selectedMember.selected_commission_count }} db
          </div>
        </div>
        <div v-if="memberClientsGroups.length > 0">
          <b-collapse v-for="(group, index) in memberClientsGroups"
                      :key="`memberClientsGroups_${index}`"
                      :open="false"
                      :aria-id="`memberClientsGroups_collapse_${index}`">
            <template #trigger="props">
              <div class="has-background-primary has-text-white p-2 mt-1 is-unselectable"
                   :aria-controls="`memberClientsGroups_collapse_${index}`"
                   :aria-expanded="props.open">
                <span>{{ group.name }}</span>
                <b-icon class="fa-pull-right"
                        :icon="props.open ? 'caret-up' : 'caret-down'"/>
              </div>
            </template>
            <PersonClientSelect v-if="group.clients.length > 0"
                                :firm-list="group.clients"
                                @deleteAssignment="deleteAssignment"
                                @selectClient="selectClient"/>
            <h2 v-else-if="!isLoading"
                class="title is-3">Nem takálható ügyfél</h2>
          </b-collapse>
        </div>
        <h2 v-else-if="!isLoading"
            class="title is-3">Nem takálható ügyfél</h2>
        <BasicLoading class="mt-6"
                      :is-active="isLoading"/>
      </b-step-item>
      <b-step-item step="3"
                   label="Ellenőr, kérdőív választás"
                   :clickable="false">
        <h1 class="title has-text-centered">Ellenőr választás</h1>
        <div class="buttons">
          <b-button @click="backStep"
                    type="is-primary"
                    icon-left="arrow-circle-left">
            Vissza
          </b-button>
          <b-button @click="backStep(true)"
                    type="is-primary"
                    icon-left="arrow-circle-left">
            Vissza az ellenőrzésekhez
          </b-button>
        </div>
        <div v-if="selectedMember"
             class="columns is-multiline has-background-grey-lighter mt-2 is-gapless p-2">
          <div class="column is-3 has-text-weight-bold">Név</div>
          <div class="column is-5">{{ selectedMember.name }}</div>
          <div class="column is-2 has-text-weight-bold">Előző Ellenőr</div>
          <div class="column is-2">{{ selectedMember.utolso_ellenorzes_ellenor }}</div>
          <div class="column is-3 has-text-weight-bold">Területi szervezet</div>
          <div class="column is-5">{{ selectedMember.region }}</div>
          <div class="column is-2 has-text-weight-bold">Előző Eredmény</div>
          <div class="column is-2">{{ selectedMember.utolso_ellenorzes_eredmenye }}</div>
          <div class="column is-3 has-text-weight-bold">Cím</div>
          <div class="column is-5">{{ selectedMember.address }}</div>
          <div class="column is-2 has-text-weight-bold">Előző Év</div>
          <div class="column is-2">{{ selectedMember.utolso_ellenorzes_eve }}</div>
          <div v-if="selectedClient"
               class="column is-3 has-text-weight-bold">
            Ügyfél
          </div>
          <div v-if="selectedClient"
               class="column is-9">
            {{ selectedClient.name }}
          </div>
        </div>
        <InspectorAndSurveySelect v-if="inspectorList.length > 0"
                                  :inspector-list="inspectorList"
                                  :regions="regions"
                                  :qualifications="qualifications"
                                  :specializations="specializations"
                                  @selectInspector="selectInspector"/>
        <h2 v-else-if="!isLoading"
            class="title is-3">Nem takálható ellenőr</h2>
        <BasicLoading class="mt-6"
                      :is-active="isLoading"/>
      </b-step-item>
    </b-steps>
    <h2 v-else
        class="title is-3">
      Az adatok betöltése közben hiba történt.
    </h2>
  </div>
</template>

<script>
import { arrayToString, formattedDate, getInspectionSelectionStatusClass } from '@/utils/utils'
import InspectableMembersTable from '@/components/InspectionAssign/InspectableMembersTable'
import InspectorAndSurveySelect from '@/components/InspectionAssign/InspectorAndSurveySelect'
import PersonClientSelect from '@/components/InspectionAssign/PersonClientSelect'
import BasicLoading from '@/components/Loading'

export default {
  name: 'Home',
  // eslint-disable-next-line vue/no-unused-components
  components: { BasicLoading, PersonClientSelect, InspectorAndSurveySelect, InspectableMembersTable },
  data: () => {
    return {
      isLoading: true,
      activeStep: 0,
      selectedMember: null,
      selectedClient: null,
      selectedInspector: null,
      regions: [],
      qualifications: [],
      specializations: [],
      inspectableMembers: [],
      memberClientsGroups: [],
      inspectorList: [],
      selectableSurveyOptions: [],
      hasCreateError: false,
    }
  },
  methods: {
    arrayToString,
    formattedDate,
    getInspectionSelectionStatusClass,
    async selectMember (member) {
      this.isLoading = true
      this.selectedMember = member
      if (member.type === 'FIRM') {
        this.activeStep = 2
        this.inspectorList = await this.$store.dispatch('fetchInspectorsList')
        this.selectableSurveyOptions = await this.$store.dispatch('fetchSurveyOptions', 2)
        // TODO: select client client
      } else {
        this.activeStep = 1
        this.memberClientsGroups = await this.$store.dispatch('fetchMemberClients', member.id)
        this.selectableSurveyOptions = await this.$store.dispatch('fetchSurveyOptions', 3)
      }
      this.isLoading = false
    },
    async selectClient (client) {
      this.isLoading = true
      this.selectedClient = client
      this.activeStep = 2
      this.inspectorList = await this.$store.dispatch('fetchInspectorsList')
      this.isLoading = false
      console.log('select client', client)
    },
    selectInspector (inspector) {
      this.selectedInspector = inspector
      this.$store.dispatch('openModal', {
        modalName: 'InspectionAssignSurveySelectModal',
        data: {
          selectableSurveys: this.selectableSurveyOptions,
          member: this.selectedMember,
          client: this.selectedClient,
          inspector: this.selectedInspector,
          successCallback: (memberItems, clientItems) => {
            this.refreshMemberRows(memberItems)
            this.refreshSelectedMember(memberItems)
            this.refreshClientRows(clientItems)
            this.backStep()
          },
        },
      })
      console.log('select inspector', inspector)
    },
    backStep (isForceToZero = false) {
      if (Number(this.activeStep) === 2 && this.selectedMember && this.selectedMember.type === 'PERSON') {
        this.activeStep = 1
        this.selectedClient = null
        this.selectedInspector = null
        this.inspectorList = []
      } else {
        this.activeStep = 0
        this.selectedMember = null
        this.selectedClient = null
        this.selectedInspector = null
        this.memberClientsGroups = []
        this.inspectorList = []
        this.selectableSurveyOptions = []
      }
      if (isForceToZero === true) this.backStep()
    },
    refreshMemberRows (newMemberRows) {
      let remainingRefreshableNewMember = newMemberRows.length
      for (const memberIndex in this.inspectableMembers) {
        for (const newMember of newMemberRows) {
          if (Number(newMember.id) === Number(this.inspectableMembers[memberIndex].id)) {
            this.inspectableMembers[memberIndex] = newMember
            remainingRefreshableNewMember--
            // newMemberRows = newMemberRows.slice(1)
            break
          }
        }
        // if (newMemberRows.length === 0) break
        if (remainingRefreshableNewMember === 0) break
      }
      this.inspectableMembers = [...this.inspectableMembers]
    },
    refreshClientRows (newClientRows) {
      for (const clientGroupsIndex in this.memberClientsGroups) {
        for (const clientIndex in this.memberClientsGroups[clientGroupsIndex].clients) {
          for (const newClient of newClientRows) {
            if (Number(newClient.id) === Number(this.memberClientsGroups[clientGroupsIndex].clients[clientIndex].id)) {
              this.memberClientsGroups[clientGroupsIndex].clients[clientIndex] = newClient
              this.memberClientsGroups[clientGroupsIndex].clients = [...this.memberClientsGroups[clientGroupsIndex].clients]
              newClientRows = newClientRows.slice(1)
              break
            }
          }
          if (newClientRows.length === 0) break
        }
        if (newClientRows.length === 0) break
      }

      this.memberClientsGroups = [...this.memberClientsGroups]
    },
    refreshSelectedMember (newMemberRows) {
      if (this.selectedMember) {
        for (const newMember of newMemberRows) {
          if (Number(newMember.id) === Number(this.selectedMember.id)) {
            this.selectedMember = newMember
            break
          }
        }
      }
    },
    selectConnectedMember (connectedMemberID) {
      this.backStep()
      for (const member of this.inspectableMembers) {
        if (Number(member.id) === Number(connectedMemberID)) {
          this.selectMember(member)
          break
        }
      }
    },
    async deleteAssignment (dynamicID, isClientSelect = false) {
      this.isLoading = true
      const payload = {
        inspection_id: isClientSelect ? this.selectedMember.id : dynamicID,
        commission_id: isClientSelect ? dynamicID : null,
      }
      const resp = await this.$store.dispatch('deleteInspectionAssign', payload)
      // noinspection JSUnresolvedVariable
      if (resp && resp.inspection_items && resp.commission_items) {
        this.refreshClientRows(resp.commission_items)
        this.refreshMemberRows(resp.inspection_items)
        this.refreshSelectedMember(resp.inspection_items)
      }
      this.isLoading = false
    },
  },
  computed: {},
  async beforeCreate () {
    this.isLoading = true
    const massResponse = await Promise.all([
      this.$store.dispatch('fetchInspectableMembers'),
      this.$store.dispatch('fetchRegions'),
      this.$store.dispatch('fetchQualifications'),
      this.$store.dispatch('fetchSpecializations'),
    ])

    let isAllResponseSuccess = true
    massResponse.forEach(item => {
      if (!item) isAllResponseSuccess = false
    })

    if (isAllResponseSuccess) {
      this.inspectableMembers = massResponse[0]
      this.regions = massResponse[1]
      this.qualifications = massResponse[2]
      this.specializations = massResponse[3]
    } else {
      this.hasCreateError = true
    }
    this.isLoading = false
  },
}
</script>

<style scoped>
.step-item {
  min-height: 500px;
}
</style>
