<template>
  <div>
    <b-table
      :data="tableData"
      class="mb-6"
      default-sort="date"
      default-sort-direction="desc"
      sticky-header
      striped
      ref="memberTable"
      :debounce-search="1000"
      :paginated="true"
      :per-page="itemPerPage"
      pagination-position="top"
      height="70vh"
    >
      <div class="position-absolute">
        <b-button @click="$refs.memberTable.filters = {}"
                  class="is-inline-block"
                  type="is-primary">
          Szűrök visszaállítása
        </b-button>
        <b-select v-model="itemPerPage"
                  class="is-inline-block ml-3">
          <option
            v-for="option in [25, 50, 100]"
            :value="option"
            :key="option">
            {{ option }}
          </option>
        </b-select>
      </div>
      <b-table-column label=""
                      width="50"
                      v-slot="props"
                      sticky
                      header-class="has-background-success has-text-white">
        <b-field v-if="$store.getters.minUserLevelAdmin || Number(props.row.inspection_status) === 1">
          <b-tooltip label="Kiválaszt"
                     position="is-right">
            <b-icon icon="arrow-circle-right"
                    class="pointer-cursor "
                    @click.native="selectMember(props.row)"/>
          </b-tooltip>
        </b-field>
        <b-field v-else>
          <b-tooltip label="Nem választható"
                     type="is-warning"
                     position="is-right">
            <b-icon icon="ban"/>
          </b-tooltip>
        </b-field>
        <b-field v-if="props.row.type === 'FIRM' &&
                    (Number(props.row.selection_status) === 1 || Number(props.row.selection_status) === 2)">
          <b-tooltip label="Törlés"
                     type="is-warning"
                     class="pointer-cursor has-text-danger"
                     position="is-right">
            <b-icon icon="trash-alt"
                    @click.native="deleteAssignment(props.row.id)"/>
          </b-tooltip>
        </b-field>
      </b-table-column>
      <b-table-column field="type"
                      label="Típus"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        <b-field>
          <b-tooltip :label="getTypeText(props.row.type)"
                     class="has-text-primary"
                     position="is-top">
            <b-icon :icon="props.row.type === 'FIRM' ? 'building' : 'user-alt'"/>
          </b-tooltip>
        </b-field>
      </b-table-column>
      <b-table-column field="selection_status_name"
                      label="Státusz"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        <b-tag :type="getInspectionSelectionStatusClass(props.row.selection_status)"
               class="is-radiusless">
          {{ props.row.selection_status_name }}
        </b-tag>
      </b-table-column>
      <b-table-column field="name"
                      label="Név"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="membership_number"
                      label="Tagszám"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.membership_number }}
      </b-table-column>
      <b-table-column field="region"
                      label="Területi szervezet"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.region }}
      </b-table-column>
      <b-table-column field="address"
                      label="Cím"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.address }}
      </b-table-column>
      <b-table-column field="selected_commission_count"
                      label="Kiválasztott ügyféldosszié"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.selected_commission_count }}
      </b-table-column>
      <b-table-column field="inspection_status"
                      label="Ellenőrzés státusz"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        <b-tag :type="getInspectionStatusClass(props.row.inspection_status)"
               class="is-radiusless">
          {{ props.row.inspection_status_name }}
        </b-tag>
      </b-table-column>
      <b-table-column field="inspection_date"
                      label="Ellenőrzés dátuma"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ formattedDate(props.row.inspection_date) }}
      </b-table-column>
      <b-table-column field="inspector_name"
                      label="Ellenőr"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.inspector_name }}
      </b-table-column>
      <b-table-column field="member_status"
                      label="Státusz"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.member_status }}
      </b-table-column>
      <b-table-column field="selection_reason"
                      label="Ok"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.selection_reason }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { formattedDate, getInspectionSelectionStatusClass, getInspectionStatusClass } from '@/utils/utils'

export default {
  name: 'InspectableMembersTable',
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      itemPerPage: 25,
    }
  },
  methods: {
    formattedDate,
    getInspectionStatusClass,
    getInspectionSelectionStatusClass,
    selectMember (member) {
      this.$emit('selectMember', member)
    },
    getTypeText (type) {
      let typeTxt = type
      if (typeTxt === 'FIRM') typeTxt = 'Cég'
      if (typeTxt === 'PERSON') typeTxt = 'Magánszemély'
      return typeTxt
    },
    deleteAssignment (inspectionID) {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Biztos törli a kiválasztást?',
          confirmFunction: () => {
            this.$emit('deleteAssignment', inspectionID)
          },
        },
      })
    },
  },
}
</script>
