<template>
  <div>
    <div class="columns mt-2 has-background-grey-lighter filter-wrapper">
      <div class="column">
        <h5 class="title is-5 mb-1">Megye</h5>
        <div>
          <b-field v-for="(region, index) in regions"
                   :key="`regions_${index}`">
            <b-checkbox v-model="selectedRegions"
                        :native-value="region.value">
              {{ region.value }}
            </b-checkbox>
          </b-field>
        </div>
      </div>
      <div class="column">
        <h5 class="title is-5 mb-1">Minősítés</h5>
        <b-field v-for="(qualification, index) in qualifications"
                 :key="`qualification_${index}`">
          <b-checkbox v-model="selectedQualifications"
                      :native-value="qualification.value">
            {{ qualification.value }}
          </b-checkbox>
        </b-field>
      </div>
      <div class="column">
        <h5 class="title is-5 mb-1">Szakterület</h5>
        <b-field v-for="(specialization, index) in specializations"
                 :key="`specializations_${index}`">
          <b-checkbox v-model="selectedSpecializations"
                      :native-value="specialization.value">
            {{ specialization.value }}
          </b-checkbox>
        </b-field>
      </div>
    </div>
    <b-table
      :data="filteredInspectorList"
      class="mb-6"
      default-sort="date"
      default-sort-direction="desc"
      sticky-header
      striped
      :paginated="true"
      pagination-position="top"
      height="auto"
    >
      <b-table-column label=""
                      width="50"
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        <b-field>
          <b-tooltip label="Kiválaszt"
                     position="is-right">
            <b-icon icon="arrow-circle-right"
                    class="pointer-cursor "
                    @click.native="selectInspector(props.row)"/>
          </b-tooltip>
        </b-field>
      </b-table-column>
      <b-table-column field="name"
                      label="Név"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="membership_number"
                      label="Tagszám"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.membership_number }}
      </b-table-column>
      <b-table-column field="organization"
                      label="Régió"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.organization }}
      </b-table-column>
      <b-table-column field="qualifications"
                      label="Minősítés"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ arrayToString(props.row.qualifications) }}
      </b-table-column>
      <b-table-column field="specializations"
                      label="Szakterület"
                      width="300"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ arrayToString(props.row.specializations) }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { arrayToString, formattedDate } from '@/utils/utils'

export default {
  name: 'InspectorAndSurveySelect',
  props: {
    inspectorList: {
      type: Array,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    qualifications: {
      type: Array,
      required: true,
    },
    specializations: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      selectedRegions: [],
      selectedQualifications: [],
      selectedSpecializations: [],
    }
  },
  methods: {
    formattedDate,
    arrayToString,
    selectInspector (inspector) {
      this.$emit('selectInspector', inspector)
    },
    getTypeText (type) {
      let typeTxt = type
      if (typeTxt === 'FIRM') typeTxt = 'Cég'
      if (typeTxt === 'PERSON') typeTxt = 'Magánszemély'
      return typeTxt
    },
  },
  computed: {
    filteredInspectorList () {
      const filteredList = []
      for (const inspector of this.inspectorList) {
        let isRegionMatch = true
        let isQualificationMatch = true
        let isSpecializationMatch = true
        if (this.selectedRegions.length > 0) {
          isRegionMatch = this.selectedRegions.includes(inspector.organization)
        }
        if (this.selectedQualifications.length > 0) {
          isQualificationMatch =
            this.selectedQualifications.some(qualifications => inspector.qualifications.includes(qualifications))
        }
        if (this.selectedSpecializations.length > 0) {
          isSpecializationMatch =
            this.selectedSpecializations.some(specializations => inspector.specializations.includes(specializations))
        }
        if (isRegionMatch && isQualificationMatch && isSpecializationMatch) {
          filteredList.push(inspector)
        }
      }
      return filteredList
    },
  },
}
</script>

<style lang="scss"
       scoped>
.filter-wrapper {
  max-height: 300px;

  .column {
    overflow-y: scroll;

    h5 {
    }
  }
}
</style>
