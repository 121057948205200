<template>
  <div>
    <b-table
      :data="firmList"
      class="my-4 test-me"
      default-sort="date"
      default-sort-direction="desc"
      sticky-header
      :debounce-search="1000"
      striped
      :row-class="() => 'narrower-rows'"
      narrowed
      ref="clientTable"
      :paginated="true"
      :per-page="itemPerPage"
      pagination-position="top"
      height="auto"
    >
      <div class="position-absolute">
        <b-button @click="$refs.clientTable.filters = {}"
                  class="is-inline-block"
                  type="is-primary">
          Szűrök visszaállítása
        </b-button>
        <b-select v-model="itemPerPage"
                  class="is-inline-block ml-3">
          <option
            v-for="option in [25, 50, 100]"
            :value="option"
            :key="option">
            {{ option }}
          </option>
        </b-select>
      </div>
      <b-table-column label=""
                      width="50"
                      cell-class="normal-cell"
                      v-slot="props"
                      sticky
                      header-class="has-background-success has-text-white">
        <b-field v-if="isSelectableClient(props.row)">
          <b-tooltip label="Kiválaszt"
                     position="is-right">
            <b-icon icon="arrow-circle-right"
                    class="pointer-cursor "
                    @click.native="selectClient(props.row)"/>
          </b-tooltip>
        </b-field>
        <b-field v-else-if="Number(props.row.status) === 2">
          <b-tooltip label="Törlés"
                     type="is-warning"
                     class="pointer-cursor has-text-danger"
                     position="is-right">
            <b-icon icon="trash-alt"
                    @click.native="deleteAssignment(props.row.id)"/>
          </b-tooltip>
        </b-field>
        <b-field v-else>
          <b-tooltip label="Nem választható"
                     type="is-warning"
                     position="is-right">
            <b-icon icon="ban"/>
          </b-tooltip>
        </b-field>
      </b-table-column>
      <b-table-column field="name"
                      label="Név"
                      sortable
                      sticky
                      cell-class="sticky-modifier"
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="report_type"
                      label="Beszámoló típusa"
                      sortable
                      cell-class="test-table-row"
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.report_type }}
      </b-table-column>
      <b-table-column field="main_activity"
                      label="Főtevékenység"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.main_activity }}
      </b-table-column>
      <b-table-column field="status_name"
                      label="Státusz"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        <b-tag :type="getClientStatusClass(props.row.status)"
               class="is-radiusless">
          {{ props.row.status_name }}
        </b-tag>
      </b-table-column>

      <b-table-column field="public_interest"
                      label="Közérd."
                      width="50"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        <b-icon v-if="props.row.public_interest"
                icon="check-square"/>
        <b-icon v-else
                pack="far"
                icon="square"/>
      </b-table-column>
      <b-table-column field="mnb"
                      label="MNB"
                      width="50"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        <b-icon v-if="props.row.mnb"
                icon="check-square"/>
        <b-icon v-else
                pack="far"
                icon="square"/>
      </b-table-column>
      <b-table-column field="stock"
                      label="Értékp."
                      width="50"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        <b-icon v-if="props.row.stock"
                icon="check-square"/>
        <b-icon v-else
                pack="far"
                icon="square"/>
      </b-table-column>
      <b-table-column field="report_date"
                      label="Év"
                      numeric
                      sortable
                      cell-class="px-5"
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.report_date }}
      </b-table-column>
      <b-table-column field="summary"
                      label="Mérlegfőösszeg"
                      numeric
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ formattedCurrency(props.row.summary) }}
      </b-table-column>
      <b-table-column field="income"
                      label="Árbevétel"
                      numeric
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ formattedCurrency(props.row.income) }}
      </b-table-column>
      <b-table-column field="commision_price"
                      label="Díj"
                      numeric
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ formattedCurrency(props.row.commission_price) }}
      </b-table-column>
      <b-table-column field="work_hour"
                      label="Óra"
                      numeric
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.work_hour }}
      </b-table-column>
      <b-table-column field="report_interval"
                      label="Jelentés kibocsátása"
                      sortable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.report_interval }}
      </b-table-column>
      <b-table-column field="comment"
                      label="Kiadott vélemény"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.comment }}
      </b-table-column>
      <b-table-column field="pmt_tipus"
                      label="PMT típusa"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.pmt_tipus }}
      </b-table-column>
      <b-table-column field="selected_inspector_name"
                      label="Ellenőr"
                      sortable
                      searchable
                      v-slot="props"
                      header-class="has-background-success has-text-white">
        {{ props.row.selected_inspector_name }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { formattedCurrency, formattedDate } from '@/utils/utils'

export default {
  name: 'PersonClientSelect',
  props: {
    firmList: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      itemPerPage: 25,
    }
  },
  methods: {
    formattedDate,
    formattedCurrency,
    selectClient (client) {
      this.$emit('selectClient', client)
    },
    getTypeText (type) {
      let typeTxt = type
      if (typeTxt === 'FIRM') typeTxt = 'Cég'
      if (typeTxt === 'PERSON') typeTxt = 'Magánszemély'
      return typeTxt
    },
    isSelectableClient (client) {
      return Number(client.status) === 1
    },
    getClientStatusClass (statusID) {
      let statusClass = 'is-secondary'
      if (Number(statusID) === 1) statusClass = 'is-success'
      if (Number(statusID) === 2) statusClass = 'is-warning'
      return statusClass
    },
    deleteAssignment (clientID) {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Biztos törli a kiválasztást?',
          confirmFunction: () => {
            this.$emit('deleteAssignment', clientID, true)
          },
        },
      })
    },
  },
}
</script>

<style lang="scss"
       scoped>

div::v-deep .narrower-rows {
  td {
    white-space: nowrap;
    overflow: hidden;
    max-width: 25ch;
    text-overflow: ellipsis;
  }

  &:hover {
    td {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }

  .normal-cell {
    overflow: visible;
  }
}

::v-deep .sticky-modifier {
  left: 30px !important;
}

</style>
